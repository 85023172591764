
































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import DomainsForm from "./components/DomainsForm.vue";
import DomainsTable from "./components/DomainsTable.vue";
import { Domain } from "@/store/types";

export default Vue.extend({
  name: "DomainsPage",

  components: {
    FullScreenDialog,
    DomainsForm,
    DomainsTable
  },

  data: () => ({
    fullScreenDialog: false,
    domain: null as unknown | Domain,
  }),

  computed: {
    ...mapGetters({
      domains: "domainsModule/getDomains",
      total: "domainsModule/getTotal",
      loading: "domainsModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("domainsModule/fetch")
  },

  methods: {
    editCol(domain: Domain): void {
      this.domain = domain;
      this.openFullScreenDialog();
    },

    deleteCol(domain: Domain): void {
      this.$store.dispatch("domainsModule/delete", { name: domain.name });
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("domainsModule/fetch", payload);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.domain = null;
    },
  }
})
