

























import { CollectionV2 } from "@/store/types";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "DomainsForm",

  props: {
    domain: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        _id: "",
        name: "",
        active: true,
        created_at: new Date(),
        updated_at: new Date(),
      } as CollectionV2
    };
  },

  computed: {
    ...mapGetters({
      apps: "mobileAppsModule/getApps",
    })
  },

  mounted() {
    if (this.domain) {
      Object.assign(this.payload, this.domain)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if (this.domain) {
        this.payload.updated_at = new Date()
        this.$store.dispatch("domainsModule/update", this.payload);
      } else {
        this.$store.dispatch("domainsModule/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },
  }
});
